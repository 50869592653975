const { translate } = require('./locale')
const { getFromDB } = require('./location')
const { shortenCompanyName } = require('./text')

// Могут подписывать и давать право подписи другим
const canSignAuthorize = [
    'CEO',
    'CAN_SIGN'
]

function isName(name) {
    if(name && /^[a-zA-Zа-яА-ЯёЁ\u04D8\u04D9\u04B0\u04B1\u0406\u0456\u04A2\u04A3\u0492\u0493\u04AE\u04AF\u049A\u049B\u04E8\u04E9\u04BA\u04BB\s'-]+$/.test(name)) return true
    return false
}

function isPatronymic(name) {
    if(!name || /^[a-zA-Zа-яА-ЯёЁ\s'-]+$/.test(name)) return true
    return false
}

function isEmail(email) {
    if(email && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) return true
    return false
}

function isPhoneNumber(phone) {
    if(phone && /^\+[7]{1}\s\([7]{1}[0-9]{2}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(phone)) return true
    return false
}

function isClearPhoneNumber(phone) {
    if(phone && /^[7]{2}[0-9]{9}$/.test(phone)) return true
    return false
}

function checkIdNum(id) {
    if(!Number(id)) return false

    id = id.toString()

    if(id.length != 12) return false
    
    if(id.substring(2, 4) > 12 || id[4] > 6) return false

    if(id[4] <= 3 && id[6] > 6) return false

    let sum = 0,
        controlNumber = 0,
        altSum = 0,
        altWeight = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2],
        shortId = id.slice(0, -1) ?? '',
        last = id.slice(-1) ?? ''

    for(let a in shortId) {
        sum += Number(shortId[a]) * (Number(a)+1)
        altSum += Number(shortId[a]) * (Number(altWeight[a]))
    }

    controlNumber = sum % 11 != 10 ? sum % 11 : altSum % 11

    return last == controlNumber
}

function extractFromIdNum(id, param) {
    if(checkIdNum(id)) {
        id = id.toString()

        // type
        if(param === 'type') {
            if(id[4] <= 3) return 'person'
            if(id[4] > 3) return 'entity'
        }

        // birthdate
        if(param === 'birthdate') {
            if(id[4] <= 3) {
                let century = ''

                if(id[6] >= 1 && id[6] < 3) century = '18'
                if(id[6] >= 3 && id[6] < 5) century = '19'
                if(id[6] >= 5 && id[6] < 7) century = '20'
                if(id[6] >= 7) return `No ${param} - incorrect id`

                return `${id.slice(4, 6)}.${id.slice(2, 4)}.${century}${id.slice(0, 2)}`
            } else {
                return 'No birthdate (not iin)'
            }
        }

        // gender
        if(param === 'gender') {
            if(id[4] <= 3) {
                if(id[6] == 0) return 'Gender unknown'
                if(id[6] != 0 && id[6] <= 6 && id[6] % 2 == 1) return 'male'
                if(id[6] != 0 && id[6] <= 6 && id[6] % 2 == 0) return 'female'
                if(id[6] >= 7) return `No ${param} - incorrect id`
            } else {
                return 'No gender (not iin)'
            }
        }

        // registration date
        if(param === 'regDate') {
            if(id[4] > 3) {
                return `${id.slice(2, 4)}.${id.slice(0, 2)}`
            } else {
                return 'No registration date (not bin)'
            }
        }

        // entity type
        if(param === 'entityType') {
            if(id[4] > 3) {
                if(id[4] == 4) return 'Resident entity'
                if(id[4] == 5) return 'Non-resident entity'
                if(id[4] == 6) return 'Individual entrepreneur'
                if(id[4] > 6) return `No ${param} - incorrect id`
            } else {
                return 'No entity type (not bin)'
            }
        }

        // entity feature
        if(param === 'entityFeature') {
            if(id[4] > 3) {
                if(id[5] == 0) return 'Head office'
                if(id[5] == 1) return 'Branch'
                if(id[5] == 2) return 'Representative office'
                if(id[5] == 3) return 'Farming (joint)'
                if(id[5] > 3) return `No ${param} - incorrect id`
            } else {
                return 'No entity feature (not bin)'
            }
        }
    } else {
        return `No ${param} - incorrect id`
    }
}

function getObjectFromPhoneNumber(phone) {
    let obj = {}

    phone = getClearPhoneNumber(phone)
    obj.prefix = '+' + phone.substr(0, 1)
    obj.operator = phone.substr(1, 3)
    obj.number = phone.substr(4, 7)

    if(obj.prefix === '' || obj.operator === '' || obj.number === '' || obj.number.length < 7) return false
    
    return obj
}

function getPhoneNumberFromObject(phone) {
    let { prefix = '', operator = '', number = '' } = phone
    
    if(phone) return prefix + ' (' + operator + ') ' + number.substr(0, 3) + '-' + number.substr(3, 2) + '-' + number.substr(5, 2)
    return
}

// +7 (777) 000-70-07 -> 77770007007
function getClearPhoneNumber(phone, countryCode = '7') {
    if(!phone) return false

    phone = phone.toString()
    
    let cleanPhone = phone.replace(/\D+/g, '')

    if(cleanPhone && cleanPhone.length === 11) return countryCode + cleanPhone.slice(1)

    return cleanPhone
}

// 77770007007 -> +7 (777) 000-70-07
function getFullPhoneNumber(phone) {
    if(!phone) return ''

    return getPhoneNumberFromObject(getObjectFromPhoneNumber(phone))
}

function clearDublicateLocalities(str) {
    const arr = str.split(', ')
    return arr.filter((value, index) => arr.indexOf(value) === index).join(', ')
}

function getUsername(title, shorted = null) {
    let { lastName = '', firstName = '', patronymic = '', company_name = '' } = title
    let _title = title?.title || ''

    lastName = clearQuotes(lastName)
    firstName = clearQuotes(firstName)
    patronymic = clearQuotes(patronymic)
    company_name = clearQuotes(company_name)

    if(company_name) return shortenCompanyName(company_name).toUpperCase()

    if(shorted !== null && shorted === true) {
        firstName = firstName ? firstName.slice(0, 1) + '.' : ''
        patronymic = patronymic ? patronymic.slice(0, 1) + '.' : ''
    }

    let username = [lastName, firstName, patronymic].filter(Boolean).join(' ')

    if(!username) return _title ? _title.toUpperCase() : '-'

    return username.toUpperCase()
}

function clearQuotes(str) {
    str = str.replace(/"/g, '')
    str = str.replace(/'/g, '')
    str = str.replace(/«/g, '')
    str = str.replace(/»/g, '')

    return str
}

// Проверяет, может ли пользователь наделять правом подписи
function isCanSignAuthorize(arr = []) {
    let can = false

    for(let i in arr) {
        if(canSignAuthorize.includes(arr[i])) {
            can = true
            break
        }
    }

    return can
}

// Проверяет, имеет ли пользователь право подписи от имени компании
async function checkCanSignFromCompany(companyIdNum = '', userIdNum = '') {
    if(!companyIdNum || !userIdNum) return false

    let t = 'sign_rights'
    
    let q = await db.select(t, {
        filter: { company_id_num: companyIdNum, user_id_num: userIdNum }
    })

    return q.length > 0
}

async function getBics() {
    let t = 'bank_bics'
    let q = await db.select(t)

    return q
}

async function getBankCodes() {
    let t = 'bank_bics'
    let q = await db.execute(`SELECT code FROM ${t};`)

    return q
}

async function checkIban(iban) {
    if(iban.length == 0)
        return iban
    //Check length. Kazakhstan IBAN is always 20 characters long
    if(iban.length != 20)
        return false
    
    //Check if IBAN is KZ
    if (iban.slice(0, 2) != 'KZ') 
        return false

    if (checkCheckDigits(iban) == false)
        return false

    let bankCodes = await getBankCodes()
    let isValidCode = false

    for(let i in bankCodes) {
        if(iban.substring(4, 7) == bankCodes[i].code){
            isValidCode = true
            break
        }
    }

    if(!isValidCode)
        return false

    return iban
}

function checkCheckDigits(iban) {
    iban = iban.substring(4) + iban.substring(0, 4)

    let transformedIban = ''

    for (let i = 0; i < iban.length; i++) {
        const char = iban[i];

        // Check if the character is a letter
        if (/[A-Z]/i.test(char)) {
            // Convert to uppercase and replace with ASCII value - 55
            const asciiValue = char.toUpperCase().charCodeAt(0) - 55;
            transformedIban += asciiValue;
        } else {
            // Keep the digits as they are
            transformedIban += char;
        }
    }

    while(parseInt(transformedIban) >= 97) {
        let remainder = parseInt(transformedIban.substring(0, 9)) % 97

        transformedIban = remainder + transformedIban.substring(9) 
    }

    if(transformedIban!=1) {
        return false
    }

    return iban
}

async function getCompanyRole(idNum, companyIdNum) {
    let userId = await db.query(`SELECT DISTINCT us1.user_id
    FROM user_settings us1
    INNER JOIN user_settings us2 ON us1.user_id = us2.user_id
    WHERE
        us1.\`key\` = 'iinBin' AND us1.value = '${idNum}'
        AND us2.\`key\` = 'bin' AND us2.value = '${companyIdNum}';`)

    if(userId.length == 0) return false

    let q = await db.selectOne('user_settings', {
        filter: {
            user_id: userId[0].user_id,
            key: 'companyRole'
        }
    })

    return q ? q.value : false
}

module.exports = {
    isName,
    isPatronymic,
    isEmail,
    isPhoneNumber,
    isClearPhoneNumber,
    checkIdNum,
    extractFromIdNum,
    getFullPhoneNumber,
    getObjectFromPhoneNumber,
    getPhoneNumberFromObject,
    getClearPhoneNumber,
    getCompanyRole,
    getUsername,
    isCanSignAuthorize,
    checkCanSignFromCompany,
    getBics,
    checkIban
}